<div class="container update-members main width-alignment" *ngIf="!showGroupAccessForm">
    <div class="col-12 Individual createGroup">
        <label for="select" class="lable-size update" *ngIf="product != 'corporate'">Update Groups</label>
        <label for="select" class="lable-size update" *ngIf="product == 'corporate'">Update Departments</label>
        <div class="row">
            <div class="col-3"></div>
            <div class="col-6">
                <div class="form-group " id="selectbx">
                  <div class="input-group group-custom-align court"
                       *ngFor="let grp of groupList">
                    <div class="multicheck form-control textbox"
                       [class.active]="selectedIds.indexOf(grp.id) > -1">
                      <div class="callselect" [innerHtml]="grp.name"></div>
                      <input class="form-check-inpu calimg calitem input-group-app end checkboxitem"
                             #inputEl [ngClass]="{'active': selectedIds.includes(grp.id)}"
                             [checked]="selectedIds.includes(grp.id)"
                             type="checkbox" (change)="selectGrp(grp, inputEl.checked)">
                    </div>
                  </div>
                </div>
            </div>
        </div>
     <div class="btnbox">
        <button type="button" class="btn btn-default btncancel" (click)= "cancel()">Cancel</button>
        <button type="button" class="btn btn-default btnsave" [disabled]="!isSaveEnable" 
        [ngClass]="{'disabled': !isSaveEnable}" (click)="save()">Save</button>
     </div>
</div>

<!--Update access dialog-->
<div class="modal" id="modalSuccess" tabindex="-1" style="display:block;" *ngIf="updateSuccessModal">
  <div class="modal-dialog">
      <div class="modal-content">
          <button type="button" class="btn-close pull-right remBtn" data-bs-dismiss="modal" aria-label="Close" (click)='updateSuccessModal = false;getMember()'></button>
          <div class="modal-body">
              <div class="alertpara"><p class="alertparatxt">Success</p></div>
          </div>
          <div class="alertext">Congratulations!!<br>You have successfully updated group access for <b><strong>{{memData.name}}</strong></b></div>
          <div class="center">
            <button class="btn savecls" (click)='getMember()'>OK</button>
          </div>  
      </div>
  </div>
</div>

<!-- Confirmation Modal -->
<div class="modal fade" id="modalCancel" tabindex="-1" aria-labelledby="modalCancelLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<button type="button" class="btn-close remBtn" data-bs-dismiss="modal" aria-label="Close"></button>
			<div class="modal-body">
				<div class="alertpara"><p class="alertparatxt">Alert</p></div>
			</div>
			<div class="alertext conf">Changes you made will not be saved. <br>Do you want to continue?</div>
			<div class="alertbutton">
				<button class="btn btn-default alertbtn alertbtnyes" data-bs-dismiss="modal" aria-label="Close">No</button>
				<button class="btn btn-default alertbtn alertbtnno" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()">Yes</button>
			</div>
		</div>
	</div>
</div>